import React from 'react';
import Layout from '../components/Layout/Layout';
import SEO from '../components/SEO';

function Features() {
  return (
    <Layout>
      <SEO title="Features and benefits" />
      <section className="inner-hero bg-lightgreen">
        <div className="container">
          <div className="inner-hero-content text-center">
            <h1 className="text-primary font-medium mb-2">
              Features &amp; Benefits
            </h1>
          </div>
        </div>
      </section>

      <section className="section-gap">
        <div className="container">
          <div className="sticky-product-features">
            <div className="row ">
              {[
                {
                  id: 0,
                  icon: '/img/no-incorrect-medicine.svg',
                  title: 'No incorrect medicine consumption',
                  description:
                    'EFFIPAC eliminates the possibility of incorrect medicine consumption as all the medicines are pre-sorted into individual pouches using high-end technology.',
                },
                {
                  id: 1,
                  icon: '/img/no-misplacement.svg',
                  title: 'No misplacement of medicines',
                  description:
                    'EFFIPAC sorts and organizes the medicines in pouches that are sealed and hence misplacement of medicines is avoided.',
                },
                {
                  id: 2,
                  icon: '/img/no-confusion.svg',
                  title: 'No confusion about medicines',
                  description:
                    'Patients or their caretakers don’t need to remember complex medicine names. They just have to remember the ‘pouch’ of their packed medicines.',
                },
                {
                  id: 3,
                  icon: '/img/no-mixing-up.svg',
                  title: 'No mixing up of medicines',
                  description:
                    'Your medicines are curated and packed with clear instructions consisting of respective patients’ names for identification, thus avoiding medicine mix-ups.',
                },
                {
                  id: 4,
                  icon: '/img/no-forgetting.svg',
                  title: 'No forgetting medicines',
                  description:
                    'Effipac prompts the patients to take the right medicines at the right time.',
                },
                {
                  id: 5,
                  icon: '/img/no-damage.svg',
                  title: 'No damage to medicines',
                  description:
                    'While sorting the medicines, Effipac retains the individual packaging of pills and they are further sealed in pouches giving extra prevention.',
                },
              ].map(({ id, icon, title, description }) => (
                <div key={id} className="col col-4">
                  <div className="feature-single feature-text py-2 md:py-4">
                    <div className="feature-icon w-[88px] mb-1">
                      <img src={icon} alt={title} />
                    </div>
                    <h3 className="heading-smallest w-full text-primary mb-2 ">
                      {title}
                    </h3>

                    <p className="max-w-[350px]">{description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default Features;
